<template>
  <TopMenu :breadcrumbs="breadcrumbs"/>
  <div class="load-container" :class="{'load' : isLoad}">
    <div class="content" v-if="!isLoad && !isError">

    	<div class="block" v-if="activeModules.length > 0">
    		<div class="block__header">
    			Подключенные модули
    		</div>
				<div class="block__modules">

					<div 
						class="module" 
						v-for="(module) in activeModules" :key="module.moduleNum">

						<div class="module__top">
    					<div class="module__ico"><component :is="module.moduleNum" color="#008DD2"></component></div>
    					<div class="module__name">
    						
    						{{ module.module }}
    						
    						<router-link class="link-default module__link" :to="{name: 'PageOrganizationHouseTechMapModule', params: {organization_id:organization_id, house_id:house_id, module_id:module.moduleNum}}">
    							<span> Перейти к модулю </span>
    							<img class="btn--ico btn--ico--right" src="@/assets/img/mark-right-blue.svg">
    						</router-link>

    					</div>

							<div class="module__fotofix">
								
								<TooltipContainer v-if="modules[module.moduleNum].workImgRequired">
										<template v-slot:content>
											<img src="@/assets/img/camera-blue-p.svg">
										</template>
										<template v-slot:info>
											Фотофиксация работ <br /> включена
										</template>
								</TooltipContainer>

								<TooltipContainer v-if="!modules[module.moduleNum].workImgRequired">
										<template v-slot:content>
											<img src="@/assets/img/camera-grey-p.svg">
										</template>
										<template v-slot:info>
											Фотофиксация работ <br /> выключена
										</template>
								</TooltipContainer>

								<TooltipContainer v-if="modules[module.moduleNum].reviewImgRequired">
										<template v-slot:content>
											<img src="@/assets/img/camera-blue-o.svg">
										</template>
										<template v-slot:info>
											Фотофиксация осмотров <br /> включена
										</template>
								</TooltipContainer>

								<TooltipContainer v-if="!modules[module.moduleNum].reviewImgRequired">
										<template v-slot:content>
											<img src="@/assets/img/camera-grey-o.svg">
										</template>
										<template v-slot:info>
											Фотофиксация осмотров <br /> выключена
										</template>
								</TooltipContainer>
								

							</div>
						</div>
						<div class="module__bottom">
							<div class="module__left">
								<template v-for="n in modules[module.moduleNum].epics.length" :key="'epic-' + n-1">
									<div class="module__epic" v-if="n-1 < modules[module.moduleNum].epics.length / 2">
										<div class="module__epic-left">
											<div class="module__epic-num"> {{ modules[module.moduleNum].epics[n-1].num }} </div>
											<div class="module__epic-name"> {{ modules[module.moduleNum].epics[n-1].epic }} </div>
										</div>
										<div class="module__epic-status" v-if="modules[module.moduleNum].epics[n-1].dateActiveEpic !== null"> 
											{{ $moment(modules[module.moduleNum].epics[n-1].dateActiveEpic).format('DD.MM.YYYY') }} 
										</div>
										<div class="module__epic-status module__epic-status--inactive" v-else></div>
									</div>
								</template>
							</div>
							<div class="module__right">
								<template v-for="n in modules[module.moduleNum].epics.length" :key="'epic-' + n-1">
									<div class="module__epic" v-if="n-1 >= modules[module.moduleNum].epics.length / 2">
										<div class="module__epic-left">
											<div class="module__epic-num"> {{ modules[module.moduleNum].epics[n-1].num }} </div>
											<div class="module__epic-name"> {{ modules[module.moduleNum].epics[n-1].epic }} </div>
										</div>
										<div class="module__epic-status" v-if="modules[module.moduleNum].epics[n-1].dateActiveEpic !== null"> 
											{{ $moment(modules[module.moduleNum].epics[n-1].dateActiveEpic).format('DD.MM.YYYY') }} 
										</div>
										<div class="module__epic-status module__epic-status--inactive" v-else></div>
									</div>
								</template>
							</div>
						</div>
    			</div>

				</div>
    	</div>

    	<div class="block" v-if="inactiveModules.length > 0">
    		<div class="block__header">
    			Неактивные модули
    		</div>
    		<div class="block__modules">
    			<div class="row">
    				<div class="col-lg-3 col-md-4 col-sm-4 col-xs-4" v-for="(module) in inactiveModules" :key="module.moduleNum">
    					<div class="module module--incative">
								<div class="module__top">
		    					<div class="module__ico"><component :is="module.moduleNum" color="#AEC1CC"></component></div>
		    					<div class="module__name">{{ module.module }}</div>
								</div>
		    			</div>
    				</div>
    			</div>
    		</div>
    	</div>

    	<div class="block">
    		<div class="block__body">
    			<router-link :to="{name: 'PageHouseMain', params: {organization_id: organization_id, house_id: house_id} }" class="btn btn--lg btn--outline">
            <img class="btn--ico btn--ico--left" src="@/assets/img/prew-arrow-blue.svg">
            <div class="btn--text"> Вернуться </div>
            <div class="btn--loader"></div>
          </router-link>
    		</div>
    	</div>
    	
  	</div>
    <ErrorContent  v-else/>
	</div>
</template>

<script setup>

  import TopMenu from '@/components/TopMenu.vue'
  import ErrorContent from '@/components/ErrorContent.vue'
  import TooltipContainer from '@/components/ux/TooltipContainer.vue'

  import A from '@/components/icons/modules/A.vue';
  import B from '@/components/icons/modules/B.vue';
  import C from '@/components/icons/modules/C.vue';
  import D from '@/components/icons/modules/D.vue';
  import E from '@/components/icons/modules/E.vue';
  import F from '@/components/icons/modules/F.vue';
  import G from '@/components/icons/modules/G.vue';
  import H from '@/components/icons/modules/H.vue';

</script>

<script>
  export default {
    
    name: 'PageOrganizationHouseTechMap',

    components: { A, B, C, D, E, F, G, H },

    data: () => ({
      isLoad: true,
      isError: false,

      breadcrumbs: [],
      
      organization: null,
      house: null,

      modules: null,
      map: null,
    }),

    computed: {
      organization_id () {
        return this.$route.params.organization_id;
      },
      house_id () {
        return this.$route.params.house_id;
      },
      activeModules () {
      	var m = [];
      	for(let mod in this.map) {
      		if(this.map[mod].active) {
      			m.push(this.map[mod]);
      		}
      	}
      	return m;
      },
      inactiveModules () {
      	var m = [];
      	for(let mod in this.map) {
      		if(!this.map[mod].active) {
      			m.push(this.map[mod]);
      		}
      	}
      	return m;
      },
    },

   	created () {
      Promise.all([this.loadOrganization(), this.loadHouse(), this.loadModules(), this.loadMap()])
      .then(() => {
        this.beforeRender();
        this.isLoad = false;
      })
      .catch((e)=> {
        console.log(e);
        this.isError = true;
        this.isLoad = false;
      })
    },

    methods: {

    	beforeRender () {
        // Формируем крошки
        this.breadcrumbs = [
        	{ name: this.$route.meta.name, link: this.$route.matched[0].path, ico: this.$route.meta.ico },
        	{ name: this.organization.name, link: {name:'PageOrganization', params: {organization_id: this.organization_id}}},
        	{ name: this.house.address, link: {name:'PageHouseMain', params: {house_id: this.house_id}} },
        	{ name: 'Техкарта', link: '' },
      	];
    	},

    	async loadMap () {
    		const r = await this.axios.get(this.$store.state.api_endpoint + '/houses/' + this.house_id + '/techMap');
        if(r.status == 200) {
        	this.map = r.data.modules;
        }
    	},
    	async loadModules() {
    		const r = await this.axios.get(this.$store.state.api_endpoint + '/houses/' + this.house_id + '/modules');
        if(r.status == 200) {
        	this.modules = r.data.data;
        }
    	},
    	async loadOrganization () {
        const r = await this.axios.get(this.$store.state.api_endpoint + '/companies/' + this.organization_id);
        if(r.status == 200) {
          this.organization = r.data;
        }
    	},
    	async loadHouse () {
        const r = await this.axios.get(this.$store.state.api_endpoint + '/houses/' + this.house_id + '?detailed=true');
        if(r.status == 200) {
          this.house = r.data;
      	}
    	},
    	
    },
  }
</script>

<style scoped>

	.block {
		margin-bottom: 10px;
	}

	.block__body {
	    padding: 20px 20px;
	}

	.block__modules {
		padding: 20px;
		padding-right: 30px;
		padding-bottom: 0px;
	}




	.module {
	  margin-right: -10px;
	  margin-bottom: 20px;
	  padding: 20px;
	  border-radius: 8px;
	  border: 1px solid var(--cozh-light-grey);
	  display: block;
	  display: flex;
	  flex-direction: column;
	  align-items: flex-start;
	  justify-content: space-between;
	  transition: border .2s ease-out;
	}

	.module:hover {
/*		border-color: var(--cozh-blue);*/
	}

	.module__top {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		gap: 20px;
		margin-bottom: 20px;
		width: 100%;
		border-bottom: 1px solid var(--cozh-light-grey);
		padding-bottom: 18px;
	}

	.module__bottom {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 60px;
	}
	.module__bottom::before {
		content: '';
		position: absolute;
		left: 50%;
		top: 0px;
		height: 100%;
		width: 1px;
		background-color: var(--cozh-light-grey);
	}
	
	.module__name {
		color: var(--cozh-black);
  	font-size: 16px;
  	font-weight: 500;
  	line-height: 1.3;
  	display: flex;
  	flex-direction: column;
	}

	.module__fotofix {
		margin-left: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		gap: 10px;
	}

	.module__ico {
		min-height: 50px;
		min-width: 50px;
		background-color: rgba(var(--cozh-blue-code), .08);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.module__link {
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
	}



	.module__epic {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		gap: 10px;
	}
	.module__epic-left {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 10px;
	}
	.module__epic-num {
		font-weight: 500;
		font-size: 14px;
		color: var(--cozh-dark-grey);
		width: 25px;
	}
	.module__epic-name {
		font-weight: 500;
		font-size: 14px;
		line-height: 1.2;
		color: var(--cozh-black);
	}
	.module__epic-status {
		display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    width: 100px;
    min-width: 100px;
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    color: var(--cozh-grey);
	}
	.module__epic-status::before {
		content: '';
		min-height: 18px;
		min-width: 18px;
		border-radius: 50%;
		background-color: var(--cozh-blue);
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nOCcgaGVpZ2h0PSc2JyB2aWV3Qm94PScwIDAgOCA2JyBmaWxsPSdub25lJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J00xIDNMMyA1TDcgMScgc3Ryb2tlPSd3aGl0ZScgc3Ryb2tlLXdpZHRoPScxLjUnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLWxpbmVqb2luPSdyb3VuZCcvPjwvc3ZnPg==");
		background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    display: block;
	}
	.module__epic-status--inactive::before {
		background-color: var(--cozh-light-grey);
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nOCcgaGVpZ2h0PSc4JyB2aWV3Qm94PScwIDAgOCA4JyBmaWxsPSdub25lJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J00xIDdMNyAxTTcgN0wxIDEnIHN0cm9rZT0nd2hpdGUnIHN0cm9rZS13aWR0aD0nMS41JyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnLz48L3N2Zz4K");
	}




	.module__epic:not(:last-child) {
		margin-bottom: 10px;
	}
	.module__left {
		width: 50%;
	}
	.module__right {
		width: 50%;
	}


/*
	.module__label {
		color: var(--cozh-grey);
		font-size: 10px;
		font-weight: 600;
		text-transform: uppercase;
		line-height: 1.2;
		letter-spacing: 0.50px;
	}

	.module__text {
		color: var(--cozh-black);
		font-size: 12px;
		font-weight: 500;
		line-height: 1.3;
		margin-top: 3px;
	}

	.module__left, .module__right {
		width: 50%;
	}

	.module__left {
		margin-right: 20px;
	}*/


	.module--incative {
		height: calc(100% - 20px);
	}
	.module--incative .module__ico {
		background-color: rgba(var(--cozh-dark-grey-code), .08);
	}
	.module--incative:hover {
/*		border-color: var(--cozh-grey);*/
	}
	.module--incative .module__top {
		margin-bottom: 0px;
		padding-bottom: 0px;
		border-bottom: 0px;
		justify-content: flex-start;
	}


/*	@media (max-width: 1500px) {
		.module__name {
		    font-size: 14px;
		}
	}*/



</style>